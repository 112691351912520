<template>
  <MainLayout class="main-layout--artist-public-profile">
    <Loader
      v-if="isLoading"
      :height="$mediaQueries.isDesktop ? 800 : 700"
      class="artist-public-profile__loader">
    </Loader>

    <template v-else>
      <ArtistProfileBookingMobile
        v-if="!$mediaQueries.isDesktop"
        :artist="currentArtist"
        :bookingDate="bookingDate"
        :isPreview="isPreview">
      </ArtistProfileBookingMobile>
      <ArtistProfileHeader
        :artist="currentArtist"
        :isPreview="isPreview"
        isPublic>
      </ArtistProfileHeader>
      <ArtistProfileInfos
        :artist="currentArtist"
        isPublic>
      </ArtistProfileInfos>
      <ArtistProfilePerformance
        :artist="currentArtist"
        isPublic>
      </ArtistProfilePerformance>
      <div class="artist-public-profile__sidebar">
        <div class="artist-public-profile__sticky">
          <ArtistProfileBooking
            v-if="$mediaQueries.isDesktop"
            :date="bookingDate"
            :artist="currentArtist"
            :isPreview="isPreview">
          </ArtistProfileBooking>
        </div>
      </div>
      <ArtistProfileMedias
        :artist="currentArtist"
        isPublic>
      </ArtistProfileMedias>
      <ArtistProfileFAQ
        v-if="hasAnsweredAtLeastOneFAQ"
        :artist="currentArtist"
        isPublic>
      </ArtistProfileFAQ>
      <ArtistProfileAgenda isPublic></ArtistProfileAgenda>
      <ArtistProfileRatings
        :artist="currentArtist"
        class="mt-lg">
      </ArtistProfileRatings>
    </template>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions,
}                                     from 'vuex';

import MainLayout                     from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader                         from '../../../components/atoms/Loader/a-Loader.vue';
import ArtistProfileHeader            from './o-ArtistProfileHeader.vue';
import ArtistProfileInfos             from './o-ArtistProfileInfos.vue';
import ArtistProfilePerformance       from './o-ArtistProfilePerformance.vue';
import ArtistProfileBooking           from './o-ArtistProfileBooking.vue';
import ArtistProfileMedias            from './o-ArtistProfileMedias.vue';
import ArtistProfileFAQ               from './o-ArtistProfileFAQ.vue';
import ArtistProfileAgenda            from './o-ArtistProfileAgenda.vue';
import ArtistProfileRatings           from './o-ArtistProfileRatings.vue';
import ArtistProfileBookingMobile     from './o-ArtistProfileBookingMobile.vue';


export default {
  name: 'p-ArtistPublicProfile',
  components: {
    MainLayout,
    Loader,
    ArtistProfileHeader,
    ArtistProfileInfos,
    ArtistProfilePerformance,
    ArtistProfileBooking,
    ArtistProfileMedias,
    ArtistProfileFAQ,
    ArtistProfileAgenda,
    ArtistProfileRatings,
    ArtistProfileBookingMobile,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isPreview: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState('SearchArtist', ['currentArtist', 'isLoading']),
    ...mapGetters({
      bookingDate: 'SearchArtist/BOOKING_DATE',
      hasAnsweredAtLeastOneFAQ: 'SearchArtist/HAS_ANSWERED_QUESTIONS',
    }),
  },
  async created() {
    await this.fetchArtistPublicProfile(this.id);
  },
  methods: {
    ...mapActions({
      fetchArtistPublicProfile: 'SearchArtist/FETCH_ARTIST_PUBLIC_PROFILE',
    }),
  },
}

</script>

<style lang="scss">
.artist-public-profile {
  &__loader {
    grid-column: 1 / -1;
  }

  &__sidebar {
    grid-row: 3 / 4;

    @media screen and ($desktop) {
      grid-row: span 6;
    }
  }

  &__sticky {
    position: sticky;
    top: calc(var(--space-header-height) + var(--space-lg));

    > *:not(:last-child) {
      margin-bottom: var(--space-md);
    }
  }
}
</style>
