<template>
  <section class="artist-profile-ratings__wrapper">
    <Heading
      :tag="'h2'"
      :level="'h3'"
      class="mb-lg">
      {{ $t('common.ratings') }}
    </Heading>

    <div v-if="artist.ratings && artist.ratings.length">
      <div class="artist-profile-ratings__rating">
        <span class="artist-profile-ratings__author">
          John White
        </span>
        <div>
          <Icon
            v-for="(rating, index) in 5"
            :variant="'star-outline'"
            :key="`rating-${index}`">
          </Icon>
        </div>
        <Paragraph class="artist-profile-ratings__rating-text">
          Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum, quippe cum propter virtutem et probitatem etiam eos, quos numquam vidimus, quodam modo diligamus.
        </Paragraph>
      </div>
      <Button
        isWhite
        type="button">
        Tous les avis (trad)
      </Button>
    </div>
    <Paragraph
      v-else
      :size="'sm'">
      {{ $t('artist.dashboard.menu.header.rating.noRating') }}
    </Paragraph>
  </section>
</template>

<script>

import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import Icon           from '../../../components/atoms/Icon/a-Icon.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button         from '../../../components/atoms/Button/a-Button.vue';


export default {
  name: 'o-ArtistProfileRatings',
  components: {
    Heading,
    Icon,
    Paragraph,
    Button,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
  },
}

</script>

<style lang="scss">

.artist-profile-ratings {
  &__wrapper {
    font-family: var(--font-stack-secondary);
    grid-row: 8 / 9;
  }

  &__rating {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: var(--space-base);
    grid-column-gap: var(--space-lg);
    margin-bottom: var(--space-md);
  }

  &__author {
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
  }

  &__rating-text {
    grid-column: 1 / -1;
  }
}

</style>
