<template>
  <section
    class="artist-profile-booking__wrapper">
    <form
      @submit.prevent="handleSubmit"
      class="artist-profile-booking__form"
      ref="form"
      novalidate>
      <header>
        <Paragraph v-if="!selectedAvailability.timestamp">
          {{ $t('artist.dashboard.menu.priceCard.title') }}
        </Paragraph>
        <Heading
          :tag="'span'"
          :level="'h1'"
          isCapitalized>
          {{ selectedAvailabilityPrice || '-' }} €
        </Heading>
        <Paragraph>
          {{ $t('artist.dashboard.menu.priceCard.subTitle') }}
        </Paragraph>
      </header>

      <Icon
        :variant="'question-outline'"
        :size="'xs'"
        :backdropColor="'white'"
        class="artist-profile-booking__help"
        ref="help">
      </Icon>
      <Tooltip :trigger="$refs.help || {}">
        <Paragraph>
          {{ $t('artist.dashboard.client.booking.details.pricingHint') }}
        </Paragraph>
      </Tooltip>

      <button
        @focusout="event => isShowCalendar = event.currentTarget.contains(event.relatedTarget)"
        @click="isShowCalendar = !isShowCalendar"
        class="artist-profile-booking__date"
        type="button">
        <FieldInput
          :value="date"
          :label="''"
          :placeholder="date || '--/--/----'"
          isFluid
          required
          id="date-selector">
          <template #label>
            <Heading
              :tag="'label'"
              :level="'h3'"
              for="date-selector"
              class="mb-sm">
              {{ $t('artist.dashboard.landing.searchHeader.addDate') }}
            </Heading>
          </template>
        </FieldInput>
        <AgendaCalendar
          v-if="isShowCalendar"
          @availability-selected="handleAvalaibilitySelected"
          @month-changed="handleFetchArtistPublicAvailabilities"
          @mousedown.native.prevent
          @click.native.stop
          :availabilities="artistAvailabilities"
          :startDate="selectedAvailabilityStartDate"
          isPublic
          isWhiteBackground
          class="artist-profile-booking__calendar">
        </AgendaCalendar>
      </button>

      <Button
        type="submit"
        class="artist-profile-booking__book-cta">
        {{ $t('common.book')}}
      </Button>

      <!-- TODO: Link -->
      <div
        v-if="false"
        class="artist-profile-booking__rating">
        <Icon
          :variant="'star-outline'"
          class="mr-sm">
        </Icon>
        <span>
          4.3 (link)
        </span>
      </div>
    </form>

    <footer class="px-lg mt-md">
      <Paragraph class="mb-sm">
        {{ $t('artist.dashboard.client.booking.details.customQuoteHint') }}
      </Paragraph>
      <TextLink
        @click="isPreview ? false : $router.push({ ...bookArtistRoute, query: { isCustomQuote: true } })"
        :tag="'button'">
        {{ $t('artist.dashboard.client.booking.details.customQuoteCta') }}
      </TextLink>
    </footer>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                                 from 'vuex';

import Heading                    from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                       from '../../../components/atoms/Icon/a-Icon.vue';
import Button                     from '../../../components/atoms/Button/a-Button.vue';
import Tooltip                    from '../../../components/atoms/Tooltip/a-Tooltip.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import TextLink                   from '../../../components/atoms/TextLink/a-TextLink.vue';
import FieldInput                 from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import AgendaCalendar             from '../../../components/molecules/AgendaCalendar/m-AgendaCalendar.vue';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';


export default {
  name: 'o-ArtistProfileBooking',
  components: {
    Heading,
    Icon,
    Button,
    Tooltip,
    Paragraph,
    TextLink,
    FieldInput,
    AgendaCalendar,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: '--/--/----',
    },
  },
  data: () => ({
    isShowCalendar: false,
  }),
  computed: {
    ...mapState('SearchArtist', ['currentArtist', 'selectedAvailability']),
    ...mapGetters({
      artistAvailabilities: 'SearchArtist/CALENDAR_AVAILABILITIES',
      selectedAvailabilities: 'SearchArtist/CALENDAR_AVAILABILITIES_FILTER',
    }),
    selectedAvailabilityPrice() {
      const { customData } = this.artistAvailabilities.find(({ customData }) => customData.EventDate === this.selectedAvailability.EventDate) ?? {};

      return customData?.EventType === AVAILABILITIES_OPTIONS.EXCEPTION
        ? this.artist.ClientPrice.Exception
        : this.artist.ClientPrice.Normal;
    },
    bookArtistRoute() {
      return {
        name: 'ClientBooking',
        params: { id: this.artist.ID },
      };
    },
    selectedAvailabilityStartDate() {
      if (!this.selectedAvailability?.timestamp) return;

      const date = new Date(this.selectedAvailability.timestamp);
      const startDate = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };

      return startDate;
    },
  },
  methods: {
    ...mapMutations({
      setClientFilter: 'SearchArtist/SET_ACTIVE_FILTER',
      setCurrentAvailability: 'SearchArtist/SET_CURRENT_AVAILABILITY',
    }),
    ...mapActions({
      fetchArtistPublicAvailabilities: 'SearchArtist/FETCH_ARTIST_PUBLIC_AVAILABILITIES',
    }),
    async handleFetchArtistPublicAvailabilities(date) {
      const params = {
        input: {
          artistID: this.currentArtist.ID,
          date,
        },
      };

      await this.fetchArtistPublicAvailabilities(params);
    },
    handleAvalaibilitySelected(availability) {
      const { EventDate, timestamp } = availability;

      this.setClientFilter({ name: 'availability', payload: [EventDate] });
      this.setCurrentAvailability(availability);
      this.isShowCalendar = false;
      this.dateInput = timestamp;
      this.isShowCalendar = false;
    },
    handleSubmit() {
      const isValidForm = this.$refs.form.checkValidity();

      if (!isValidForm || this.isPreview) return;

      this.$router.push(this.bookArtistRoute).catch(() => {});
    },
  },
}

</script>

<style lang="scss">

.artist-profile-booking {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    grid-gap: var(--space-lg);
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);
  }

  &__help {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    align-self: center;
  }

  &__date {
    grid-column: 1 / -1;

    * {
      cursor: pointer;
    }

    input {
      pointer-events: none;
      background-color: var(--color-white) !important;
    }
  }

  &__book-cta {
    grid-column: 1 / -1;
    margin-bottom: var(--space-sm);
  }

  &__rating {
    grid-row: 3 / 4;
    grid-column: 3 / 4;
    display: flex;
    align-items: center;
  }

  &__calendar {
    z-index: 10;
    position: absolute;
    box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .05);
    transform: translateY(var(--space-sm));
  }
}

</style>
