<template>
  <article class="artist-profile-booking-mobile__wrapper">
    <Heading
      :tag="'h3'"
      :level="'h3'"
      class="artist-profile-booking-mobile__price">
      {{ artistPrice }} €
    </Heading>
    <Paragraph class="artist-profile-booking-mobile__price-hint">
      {{ $t('artist.dashboard.menu.priceCard.subTitle') }}
    </Paragraph>
    <Paragraph
      @click.native="handleClickBookingDate"
      :tag="'button'"
      :size="'sm'"
      class="artist-profile-booking-mobile__date">
      {{ bookingDate || $t('artist.dashboard.client.booking.details.chooseDate') }}
    </Paragraph>
    <Button
      @click="handleBookingRequest"
      class="artist-profile-booking-mobile__cta">
      {{
        isCustomQuote
          ? $t('artist.dashboard.client.dashboard.filter.customQuote')
          : $t('artist.dashboard.client.booking.details.reserve')
      }}
    </Button>
    <footer
      v-if="isCustomQuote"
      class="artist-profile-booking-mobile__footer">
      <Paragraph
        :size="'sm'"
        isBold
        class="artist-profile-booking-mobile__geo-message">
        {{ $t('artist.dashboard.client.booking.details.artistTooFar') }}
      </Paragraph>
    </footer>
  </article>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
}                                 from 'vuex';

import Heading                    from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                     from '../../../components/atoms/Button/a-Button.vue';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';


export default {
  name: 'o-ArtistProfileBookingMobile',
  components: {
    Heading,
    Paragraph,
    Button,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    bookingDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('SearchArtist', ['selectedAvailability']),
    ...mapGetters({
      currentArtistSelectedAvailability: 'SearchArtist/CURRENT_ARTIST_SELECTED_AVAILABILITY',
      geolocalisationFilter: 'SearchArtist/GEOLOCALISATION_FILTER',
      isCustomQuote: 'SearchArtist/IS_CUSTOM_QUOTE_NEEDED',
    }),
    artistPrice() {
      const availabilityType = (this.currentArtistSelectedAvailability || this.selectedAvailability)?.EventType;

      switch (availabilityType) {
        case AVAILABILITIES_OPTIONS.EXCEPTION:
          return this.artist?.ClientPrice?.Exception ?? '-';
        case AVAILABILITIES_OPTIONS.NORMAL:
        default:
          return this.artist?.ClientPrice?.Normal ?? '-';
      }
    },
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
    }),
    handleClickBookingDate() {
      const route = {
        name: 'ArtistProfilePublic',
        params: { id: this.artist.ID },
      };

      this.$router.push({ ...route, hash: '#artist-agenda', }, false, () => this.$router.push(route));
    },
    handleBookingRequest() {
      const isArtistAvailable = [AVAILABILITIES_OPTIONS.NORMAL, AVAILABILITIES_OPTIONS.EXCEPTION]
        .includes(this.currentArtistSelectedAvailability?.EventType);

      if (this.isPreview) return;
      if (!isArtistAvailable) {
        this.addErrors([{ message: this.$t('artist.dashboard.client.booking.details.dateMandatory'), type: 'alert' }]);
        this.$router.push({ name: 'ArtistProfilePublic', params: { id: this.artist.ID }, hash: '#artist-agenda', });

        return;
      }

      const bookArtistRoute = {
        name: 'ClientBooking',
        params: { id: this.artist.ID },
      };

      this.$router.push(bookArtistRoute).catch(() => {});
    },
  },
}

</script>

<style lang="scss">

.artist-profile-booking-mobile {
  &__wrapper {
    z-index: 10;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: var(--space-xxs) var(--space-sm);
    padding: var(--space-md);
    background-color: var(--color-white);
    box-shadow: 0 -2px 10px hsla(0, 0%, 0%, .075);
  }

  &__price {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &__price-hint {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  &__date {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    justify-self: start;
    text-decoration: underline;
    text-underline-offset: 2px;

    @include textOverflowEllipsis();
  }

  &__cta {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    margin-left: auto;
  }

  &__footer {
    grid-column: 1 / -1;
    padding-top: var(--space-xs);
  }

  &__geo-message {
    color: var(--color-alert);
  }
}

</style>
